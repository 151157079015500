import React, { Component } from 'react'
import Item from './Item'
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup'

import './Slider_client.scss'

const PAGE_SIZE = 8

const data = Array.from({ length: 24 }, (_, i) => `client${i + 1}.png`)
export class SliderClient extends Component {
  state = {
    data: data,
    active: 0,
    direction: '',
  }

  componentDidMount = () => {
    this.myTimer()
  }

  myTimer = () => {
    this.timer = setInterval(() => {
      this.slideRight()
    }, 5000)
  }

  slideLeft = () => {
    const { active, data } = this.state
    let newActive = active === 0 ? Math.ceil(data.length / PAGE_SIZE) - 1 : active - 1
    this.setState({
      active: newActive,
      direction: 'left',
    })
  }

  slideRight = () => {
    const { active, data } = this.state
    let newActive = active + 1 >= (data.length / PAGE_SIZE) ? 0 : active + 1
    this.setState({
      active: newActive,
      direction: 'right',
    })
  }

  generateItems = () => {
    var data_array = []
    var level

    for (var i = this.state.active - 2; i < this.state.active + 3; i++) {
      var index = i

      if (i < 0) {
        index = this.state.data.length + i
      } else if (i >= this.state.data.length) {
        index = i % this.state.data.length
      }

      level = this.state.active - i
      data_array.push(
        <Item key={index} level={level} data={this.state.data[index]} />
      )
    }

    return data_array
  }

  generateNumbers = () => {
    var number_array = []
    var level

    for (var i = this.state.active - 2; i < this.state.active + 3; i++) {
      var index = i

      if (i < 0) {
        index = this.state.data.length + i
      } else if (i >= this.state.data.length) {
        index = i % this.state.data.length
      }

      level = this.state.active - i
      const classNumber = 'numero' + level

      number_array.push(
        <h3 className={classNumber} key={index}>
          {`0${data.indexOf(this.state.data[index]) + 1}`}
        </h3>
      )
    }

    return number_array
  }

  generatePictures = () => {
    const { data: images } = this.state
    const chunks = []
    for (let i = 0; i < images.length; i += PAGE_SIZE) {
      const chunk = images.slice(i, i + PAGE_SIZE);
      chunks.push(chunk)
    }
    const picture_array =
      <div className="slider_client">
        {chunks[this.state.active].map((item, idx) => (
          <img
            className="client"
            key={idx}
            src={require(`../../assets/clients/${item}`)}
            alt="img"
          />
        ))}
      </div>

    return picture_array
  }

  render() {
    const wrapperClass = 'wrapper-' + this.state.direction

    return (
      <div className="slider_client">


        <CSSTransitionGroup
          transitionName="frames"
          transitionEnterTimeout={500}
          transitionLeaveTimeout={500}
        >
          <div className={wrapperClass}>
            {this.generatePictures()}
          </div>
        </CSSTransitionGroup>

        <h3 className='numero0'>
          {this.state.active + 1}
        </h3>

        {/* SETAS */}
        <img
          src={require('../../assets/right_arrow.svg')}
          onClick={() => this.slideRight()}
          className="right_arrow"
          alt="right_arrow"
        />

        <img
          src={require('../../assets/left_arrow.svg')}
          onClick={() => this.slideLeft()}
          className="left_arrow"
          alt="left_arrow"
        />
        <div className="oval" />
      </div>
    )
  }
}

export default SliderClient
